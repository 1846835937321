import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import TrendingUp from "@material-ui/icons/TrendingUp";
import {Helmet} from "react-helmet";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Info from "components/Typography/Info.js";
import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";
import Button from "components/CustomButtons/Button.js";

import blogsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/blogsStyle.js";

import cardBlog4 from "assets/img/blogimg/card-blog4.png";
import office2 from "assets/img/blogimg/office2.png";
import blog5 from "assets/img/blogimg/blog5.png";
import blog6 from "assets/img/blogimg/blog6.png";
import blog7 from "assets/img/blogimg/blog7.png";
import blog8 from "assets/img/blogimg/blog8.png";
import blog9 from "assets/img/blogimg/dashboard.png";
import bg5 from "assets/img/bg5.jpg";
import Layout from "views/Layout/Layout.js";
const useStyles = makeStyles(blogsStyle);

export default function SectionBlogs({ ...rest }) {
  const classes = useStyles();
  const schemaOrgJSONLD = [
    {
      '@context': 'http://schema.org',
      '@type': 'Website',
      'name':'Blogs',
      'description':'Blog posts of BUSLOC',
      'url':'www.busloc.io/blogs',
      'keywords':'BUSLOC, blogs BUSLOC, blogs',
      'og:description':'Blog posts of BUSLOC',
      'og:title':'BLOGS - Blog posts of BUSLOC'
      
    },
  ];
  return (
    <Layout>
       <Helmet>
     <title>BLOGS - Blog posts of BUSLOC</title>
       
       {/* JSON-Ld tags*/}
       <script type="application/ld+json">
   {JSON.stringify(schemaOrgJSONLD)}
 </script>
      </Helmet>
 
    <div className="cd-section" {...rest}>
      
     
      {/* Blogs 3 START */}
      <div className={classes.blog}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={10}
              md={10}
              className={classes.mlAuto + " " + classes.mrAuto}
            >
              <h2 className={classes.title}>Our Latest Blogposts</h2>
              <br />
              <Card plain blog className={classes.card}>
                <GridContainer>
                  <GridItem xs={12} sm={4} md={4}>
                    <CardHeader image plain>
                      <a href="https://medium.com/@buslocteam/covid-19-checks-in-busloc-part-two-attendants-checks-7b95f6820d89?source=friends_link&sk=439e43a53d517282bbff4ca539577f14" target="_blank" onClick={e => e.preventDefault()}>
                        <img src={blog9} alt="..." />
                      </a>
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${blog9})`,
                          opacity: "1"
                        }}
                      />
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${blog9})`,
                          opacity: "1"
                        }}
                      />
                    </CardHeader>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={8}>
                    
                    <h3 className={classes.cardTitle}>
                      <a href="https://medium.com/@buslocteam/covid-19-checks-in-busloc-part-three-transportation-staff-dashboard-115352264e46?source=friends_link&sk=e08247b0aced8648d1e8fbaef93dce20" target="_blank">
                      COVID-19 Checks in BUSLOC — Part Three: Transportation Staff Dashboard

                      </a>
                    </h3>
                    <p className={classes.description}>
                    Part One and Part Two of this series outlined the functionality in the BUSLOC apps to help facilitate the COVID-19 checks. 
                    This part three briefly outlines the functionality in the Dashboard tool (on a PC or laptop) available for the Transportation Staff. Initially developed to integrate with the School District’s Routing (if the District uses one) and the GPS softwares, the Dashboard (or Control Panel) module in BUSLOC…
                      <a href="https://medium.com/@buslocteam/covid-19-checks-in-busloc-part-three-transportation-staff-dashboard-115352264e46?source=friends_link&sk=e08247b0aced8648d1e8fbaef93dce20" target="_blank">
                        {" "}
                        Read More{" "}
                      </a>
                    </p>
                    <p className={classes.author}>
                      by{" "}
                      <a href="https://medium.com/@buslocteam/covid-19-checks-in-busloc-part-three-transportation-staff-dashboard-115352264e46?source=friends_link&sk=e08247b0aced8648d1e8fbaef93dce20" target="_blank" >
                        <b>Aravind</b>
                      </a>{" "}
                      , 06 August 2020

                    </p>
                  </GridItem>
                </GridContainer>
              </Card>
              <Card plain blog className={classes.card}>
                <GridContainer>
                  <GridItem xs={12} sm={4} md={4}>
                    <CardHeader image plain>
                      <a href="https://medium.com/@buslocteam/covid-19-checks-in-busloc-part-two-attendants-checks-7b95f6820d89?source=friends_link&sk=439e43a53d517282bbff4ca539577f14" target="_blank" onClick={e => e.preventDefault()}>
                        <img src={cardBlog4} alt="..." />
                      </a>
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${cardBlog4})`,
                          opacity: "1"
                        }}
                      />
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${cardBlog4})`,
                          opacity: "1"
                        }}
                      />
                    </CardHeader>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={8}>
                    
                    <h3 className={classes.cardTitle}>
                      <a href="https://medium.com/@buslocteam/covid-19-checks-in-busloc-part-two-attendants-checks-7b95f6820d89?source=friends_link&sk=439e43a53d517282bbff4ca539577f14" target="_blank">
                      COVID-19 Checks in BUSLOC — Part Two: Attendant’s Checks

                      </a>
                    </h3>
                    <p className={classes.description}>
                    In Part One of this series, we presented the options available in the free BUSLOC app for the students/parents to submit self-attestations before boarding the school bus, to help the School Districts enforce health and safety measures during the ongoing COVID-19 crisis. 
                    As outlined in a previous post, BUSLOC software has options in multiple layers for COVID-19 checks/attestations…
                      <a href="https://medium.com/@buslocteam/covid-19-checks-in-busloc-part-two-attendants-checks-7b95f6820d89?source=friends_link&sk=439e43a53d517282bbff4ca539577f14" target="_blank">
                        {" "}
                        Read More{" "}
                      </a>
                    </p>
                    <p className={classes.author}>
                      by{" "}
                      <a href="https://medium.com/@buslocteam/covid-19-checks-in-busloc-part-two-attendants-checks-7b95f6820d89?source=friends_link&sk=439e43a53d517282bbff4ca539577f14" target="_blank" >
                        <b>Aravind</b>
                      </a>{" "}
                      , 31 July 2020

                    </p>
                  </GridItem>
                </GridContainer>
              </Card>
              <Card plain blog className={classes.card}>
                <GridContainer>
                  <GridItem xs={12} sm={4} md={4}>
                    <CardHeader image plain>
                      <a href="https://medium.com/@buslocteam/covid-19-checks-in-busloc-part-one-students-parent-s-attestation-a608c64ab8d0?source=friends_link&sk=5faeee495fd10ca26184152baa8974af" target="_blank">
                        <img src={office2} alt="..." />
                      </a>
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${office2})`,
                          opacity: "1"
                        }}
                      />
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${office2})`,
                          opacity: "1"
                        }}
                      />
                    </CardHeader>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={8}>
                   
                    <h3 className={classes.cardTitle}>
                      <a href="https://medium.com/@buslocteam/covid-19-checks-in-busloc-part-one-students-parent-s-attestation-a608c64ab8d0?source=friends_link&sk=5faeee495fd10ca26184152baa8974af" target="_blank">
                      COVID-19 Checks in BUSLOC — Part One: Student’s/Parent’s Attestation
                      </a>
                    </h3>
                    <p className={classes.description}>
                    The free BUSLOC software now helps School Districts implement STARTS compliance checks for the safety of students and staff (see our earlier post). One “option” for the school districts is 
                    to get the daily COVID-19 checks performed by students or parents and collect the information before letting the student to board the school bus. …
                      <a href="https://medium.com/@buslocteam/covid-19-checks-in-busloc-part-one-students-parent-s-attestation-a608c64ab8d0?source=friends_link&sk=5faeee495fd10ca26184152baa8974af" target="_blank">
                        {" "}
                        Read More{" "}
                      </a>
                    </p>
                    <p className={classes.author}>
                      by{" "}
                      <a href="https://medium.com/@buslocteam/covid-19-checks-in-busloc-part-one-students-parent-s-attestation-a608c64ab8d0?source=friends_link&sk=5faeee495fd10ca26184152baa8974af" target="_blank">
                        <b>Aravind</b>
                      </a>{" "}
                      , 29 july 2020
                    </p>
                  </GridItem>
                </GridContainer>
              </Card>
              <Card plain blog className={classes.card}>
                <GridContainer>
                  <GridItem xs={12} sm={4} md={4}>
                    <CardHeader image plain>
                      <a href="https://medium.com/@buslocteam/implement-starts-compliance-with-free-busloc-software-for-the-safety-of-your-students-and-staff-77c8ba90b61e?source=friends_link&sk=308d114b5adce0bfa69978485272c0fc" target="_blank">
                        <img src={blog8} alt="..." />
                      </a>
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${blog8})`,
                          opacity: "1"
                        }}
                      />
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${blog8})`,
                          opacity: "1"
                        }}
                      />
                    </CardHeader>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={8}>
                 
                    <h3 className={classes.cardTitle}>
                      <a href="https://medium.com/@buslocteam/implement-starts-compliance-with-free-busloc-software-for-the-safety-of-your-students-and-staff-77c8ba90b61e?source=friends_link&sk=308d114b5adce0bfa69978485272c0fc" target="_blank">
                      Implement STARTS Compliance with Free BUSLOC Software for the Safety of Your Students and Staff
                      </a>
                    </h3>
                    <p className={classes.description}>
                    Faced with the uncertain scenario of schools openings for the Fall 2020, Student Transportation Aligned for Return to School, or STARTS Task Force, earlier this week released a tool kit to help prepare school districts, across the US, for the challenges of school start and operations in 2020–21. The STARTS tool kit is a list of guidelines “for transportation professionals and their stakeholders that 
                    can be used in the design and development of school opening plans and the development of sustainable operational support in a time of COVID-19”…
                      <a href="https://medium.com/@buslocteam/implement-starts-compliance-with-free-busloc-software-for-the-safety-of-your-students-and-staff-77c8ba90b61e?source=friends_link&sk=308d114b5adce0bfa69978485272c0fc" target="_blank">
                        {" "}
                        Read More{" "}
                      </a>
                    </p>
                    <p className={classes.author}>
                      by{" "}
                      <a href="https://medium.com/@buslocteam/implement-starts-compliance-with-free-busloc-software-for-the-safety-of-your-students-and-staff-77c8ba90b61e?source=friends_link&sk=308d114b5adce0bfa69978485272c0fc" target="_blank">
                        <b>Aravind</b>
                      </a>{" "}
                      , 24 July 2020
                    </p>
                  </GridItem>
                </GridContainer>
              </Card>
              <Card plain blog className={classes.card}>
                <GridContainer>
                  <GridItem xs={12} sm={4} md={4}>
                    <CardHeader image plain>
                      <a href="https://medium.com/@buslocteam/busloc-facilitates-covid-19-checks-for-boarding-the-school-bus-1a5e0bd770b0?source=friends_link&sk=b5a0e17c02b88f6c97096bd0dde26ec5" onClick={e => e.preventDefault()}>
                        <img src={blog7} alt="..." />
                      </a>
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${blog7})`,
                          opacity: "1"
                        }}
                      />
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${blog7})`,
                          opacity: "1"
                        }}
                      />
                    </CardHeader>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={8}>
                 
                    <h3 className={classes.cardTitle}>
                      <a href="https://medium.com/@buslocteam/busloc-facilitates-covid-19-checks-for-boarding-the-school-bus-1a5e0bd770b0?source=friends_link&sk=b5a0e17c02b88f6c97096bd0dde26ec5" onClick={e => e.preventDefault()}>
                      BUSLOC Facilitates COVID-19 Checks For Boarding the School Bus
                      </a>
                    </h3>
                    <p className={classes.description}>
                    Schools reopening for the 2020–2021 academic year has been a delicate and contentious subject. With the coronavirus still active in the US, parents, teachers, school administrators, community leaders, and politicians are still debating the steps to implement to keep the students safe from exposure to the virus. As part of their guidelines for schools and bus operators, CDC (Center for Disease Control and Prevention) 
                    recommends regular screening of staff and students for high temperatures, coughing, and other COVID-19 symptoms…
                      <a href="" onClick={e => e.preventDefault()}>
                        {" "}
                        Read More{" "}
                      </a>
                    </p>
                    <p className={classes.author}>
                      by{" "}
                      <a href="https://medium.com/@buslocteam/busloc-facilitates-covid-19-checks-for-boarding-the-school-bus-1a5e0bd770b0?source=friends_link&sk=b5a0e17c02b88f6c97096bd0dde26ec5" onClick={e => e.preventDefault()}>
                        <b>Aravind</b>
                      </a>{" "}
                      , 16 July 2020
                    </p>
                  </GridItem>
                </GridContainer>
              </Card>
              <Card plain blog className={classes.card}>
                <GridContainer>
                  <GridItem xs={12} sm={4} md={4}>
                    <CardHeader image plain>
                      <a href="https://medium.com/@buslocteam/busloc-the-free-school-bus-tracking-solution-in-these-difficult-times-713006a53437?source=friends_link&sk=01106f7f7244dabab02af0ed7d28a33c" onClick={e => e.preventDefault()}>
                        <img src={blog6} alt="..." />
                      </a>
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${blog6})`,
                          opacity: "1"
                        }}
                      />
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${blog6})`,
                          opacity: "1"
                        }}
                      />
                    </CardHeader>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={8}>
                 
                    <h3 className={classes.cardTitle}>
                      <a href="https://medium.com/@buslocteam/busloc-the-free-school-bus-tracking-solution-in-these-difficult-times-713006a53437?source=friends_link&sk=01106f7f7244dabab02af0ed7d28a33c" onClick={e => e.preventDefault()}>
                      BUSLOC — The FREE School Bus Tracking Solution In These Difficult Times
                      </a>
                    </h3>
                    <p className={classes.description}>
                    BUSLOC is a school bus tracking platform for Parents, Transportation Staff, and School Administrators. BUSLOC is FREE of charge for everyone and forever allowing schools to implement BUSLOC free of any budget constraints. No payment needs to be made by any one at any time. 
                    As the current pandemic is constraining the School District budgets, BUSLOC is a cost-free alternative for the Districts to consider.…
                      <a href="https://medium.com/@buslocteam/busloc-the-free-school-bus-tracking-solution-in-these-difficult-times-713006a53437?source=friends_link&sk=01106f7f7244dabab02af0ed7d28a33c" onClick={e => e.preventDefault()}>
                        {" "}
                        Read More{" "}
                      </a>
                    </p>
                    <p className={classes.author}>
                      by{" "}
                      <a href="https://medium.com/@buslocteam/busloc-the-free-school-bus-tracking-solution-in-these-difficult-times-713006a53437?source=friends_link&sk=01106f7f7244dabab02af0ed7d28a33c" onClick={e => e.preventDefault()}>
                        <b>Aravind</b>
                      </a>{" "}
                      , 06 July 2020
                    </p>
                  </GridItem>
                </GridContainer>
              </Card>
              <Card plain blog className={classes.card}>
                <GridContainer>
                  <GridItem xs={12} sm={4} md={4}>
                    <CardHeader image plain>
                      <a href="https://medium.com/@buslocteam/reaching-out-to-ptas-for-implementing-busloc-in-their-school-districts-3be8d3ef8482?source=friends_link&sk=b29e0c9361286fb24ccec01c526521b3" target="_blank">
                        <img src={blog5} alt="..." />
                      </a>
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${blog5})`,
                          opacity: "1"
                        }}
                      />
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${blog5})`,
                          opacity: "1"
                        }}
                      />
                    </CardHeader>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={8}>
                 
                    <h3 className={classes.cardTitle}>
                      <a href="https://medium.com/@buslocteam/reaching-out-to-ptas-for-implementing-busloc-in-their-school-districts-3be8d3ef8482?source=friends_link&sk=b29e0c9361286fb24ccec01c526521b3" target="_blank">
                      Reaching out to PTAs for Implementing BUSLOC in Their School Districts
                      </a>
                    </h3>
                    <p className={classes.description}>
                    PTAs, the Parent Teacher Associations, are vital for the smooth operations of schools. One of the tasks PTAs undertake is to decide, in cooperation with the school administrators, on programs beneficial to the students. School bus transportation is one program that can involve the PTAs. BUSLOC is a FREE school bus tracking platform to help students save time and effort in getting on board the transportation to and from school. 
                    BUSLOC Parents app allows students and parents track the school bus in real-time on a map and receive location-based text notifications…
                      <a href="https://medium.com/@buslocteam/reaching-out-to-ptas-for-implementing-busloc-in-their-school-districts-3be8d3ef8482?source=friends_link&sk=b29e0c9361286fb24ccec01c526521b3" target="_blank">
                        {" "}
                        Read More{" "}
                      </a>
                    </p>
                    <p className={classes.author}>
                      by{" "}
                      <a href="https://medium.com/@buslocteam/reaching-out-to-ptas-for-implementing-busloc-in-their-school-districts-3be8d3ef8482?source=friends_link&sk=b29e0c9361286fb24ccec01c526521b3" target="_blank">
                        <b>Aravind</b>
                      </a>{" "}
                      , 30 June 2020
                    </p>
                  </GridItem>
                </GridContainer>
              </Card>
           
            </GridItem>
          </GridContainer>
        </div>
      </div>
      {/* Blogs 3 END */}
     
    </div>
    </Layout>
  );
}
